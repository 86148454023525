import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 0 0;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  text-align: center;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};
  color: #000000;
  font-weight: bold;
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  text-align: center;
  padding-bottom: 30px;
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
`

export default () => {

  return (
    <Container fluid>
      <Wrapper>
        <Col>
          <HeadingTwo>Terms &amp; Conditions</HeadingTwo>
          <HeadingFour>Last Updated April 09, 2019</HeadingFour>

          <HeadingThree>1. GENERAL INFORMATION</HeadingThree>

          <ul>
            <li>
              Terms &amp; Conditions. These Terms &amp; Conditions (these
              “Terms”) contain the terms and conditions on which we supply
              content, products or services listed on{" "}
              <a href="https://www.savor.co/" target="_blank">
                https://www.savor.co
              </a>{" "}
              (the “Website”), through our applications (the “Apps”) or via
              other delivery methods to you (the Website and such content,
              products, services, and the Apps are collectively referred to
              herein as the “Product” or “Products”, which may be updated from
              time-to-time at the sole discretion of Savor). Please read these
              terms and conditions, carefully before ordering any Products from
              the Website or third-party App stores (e.g. the Apple App Store,
              the Android Play Store, Amazon, etc.). The terms “Savor,” “us” or
              “we” refers to Savor Wellness Inc. The term “Device” refers to the
              device which is used to access the Products including but not
              limited to computers, smartphones, and tablets. The term “you”
              refers to the user of the Products. When you order (“Order”) any
              Products, or otherwise use or access the Products, you agree to be
              bound by these Terms and all applicable laws, rules and
              regulations. You may also be asked to click “I accept” at the
              appropriate place prior to your purchase of access to the
              Products. At such time, if you do not click “I accept”, you may
              not be able to complete such purchase or gain such access. By
              using the Products, you indicate that you accept these Terms and
              that you agree to abide by them. If you do not agree to these
              Terms, please refrain from using the Products.
            </li>
          </ul>
          <ParagraphTwo>
            Our contact email address is{" "}
            <a href="mailto:support@savor.co">support@savor.co</a> . All
            correspondence to Savor including any queries you may have regarding
            your use of the Products or these Terms should be sent to this
            contact email address.
          </ParagraphTwo>
          <ul>
            <li>
              Changes to Terms & Conditions. Savor reserves the right to change
              or update these Terms, or any other of our policies or practices,
              at any time, and will notify users by posting such changed or
              updated Terms on the Website. Any changes or updates will be
              effective immediately upon posting to the Website. Your continued
              use of the Products constitutes your agreement to abide by the
              Terms as changed. Under certain circumstances, we may also elect
              to notify you of changes or updates to our Terms by additional
              means, such as pop-up or push notifications within the Products or
              email
            </li>
            <li>
              Additional Terms. These Terms and the Order set out the whole
              agreement between you and us for the supply of the Products. In
              order to participate in certain Products, you may be required to
              agree to additional terms and conditions. Those additional terms
              are hereby incorporated into these Terms. Where such terms are
              inconsistent with these Terms, the additional terms shall control.
              Please check that the details in these Terms and on the Order are
              complete and accurate before you use or commit yourself to
              purchase the Products. If you think that there is a mistake,
              please make sure that you ask us to confirm any changes in
              writing, as we only accept responsibility for statements and
              representations made in writing by an officer of Savor.
            </li>
            <li>
              Personal Information and Privacy. As part of your use of the
              Products, you affirmatively consent to the processing and storage
              of your personal information in Canada, including the processing
              and storing of your personal information in conjunction with
              tracking individual use of the Products. By using the Products,
              you acknowledge that you understand and agree that Canada may have
              different levels of protection for your personal information than
              those that exist in your country of residence, and you nonetheless
              consent to the processing and storage of your personal information
              in Canada. Please refer to our Privacy Policy for information on
              how we collect, use and disclose your personal information. You
              acknowledge and agree that your use of the Products is subject to
              our Privacy Policy. Our Privacy Policy can be found at
              savorfood.co/privacy-policy.
            </li>
            <li>
              Arbitration Notice and Class Action Waiver. Please note that these
              terms contain an arbitration clause. Except for certain types of
              disputes mentioned in the arbitration clause, you and Savor agree
              that disputes relating to these terms or your use of the products
              will be resolved by mandatory binding arbitration, and you and
              Savor waive any right to participate in a class-action lawsuit or
              class-wide arbitration.
            </li>
          </ul>

          <HeadingThree>2. MEMBERSHIPS AND SUBSCRIPTIONS</HeadingThree>

          <ParagraphTwo>
            Becoming a Member. You may sign up as a registered user of the
            Products free of charge (a “Member”). To become a Member you need to
            go to the relevant section of the Products, then submit your email
            address to us, and create a password to be used in conjunction with
            that email address. You are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your Device.
          </ParagraphTwo>

          <ParagraphTwo>
            User Information. In the course of your use of the Products, you may
            be asked to provide certain personalized information to us (such
            information is referred to hereinafter as “User Information”). This
            User Information may include information from your social networking
            profiles. Our information collection and use policies with respect
            to the privacy of such User Information are set forth in our Privacy
            Policy. For more information on our Privacy Policy please refer to
            section 1.4 above. You acknowledge and agree that you are solely
            responsible for the accuracy and content of User Information, and
            you agree to keep it up to date.
          </ParagraphTwo>

          <ParagraphTwo>
            Representation by Members. By placing an Order through the Products,
            you warrant that:
          </ParagraphTwo>
          <ol>
            <li>You are legally capable of entering into binding contracts;</li>
            <li>
              All registration information you submit is truthful and accurate;
            </li>
            <li>You will maintain the accuracy of such information;</li>
            <li>
              Your use of the Products does not violate any applicable law or
              regulation.
            </li>
          </ol>

          <ParagraphTwo>
            No Resale. You agree that you are not permitted to resell any
            Products purchased through Savor for commercial purposes.
          </ParagraphTwo>

          <ParagraphTwo>
            Use of Products by Minors. You must be the older of 18 years of age
            or the age of majority in your state, province, territory or
            country, to sign up as a registered user of the Products.
            Individuals under the age of 18 or the applicable age of majority,
            may utilize the Products only with the involvement and consent of a
            parent or legal guardian, under such person's account and otherwise
            subject to these Terms.
          </ParagraphTwo>

          <ParagraphTwo>
            Membership. As a Savor Member, you will receive access to certain
            sections, features, and functions of the Products that are not
            available to non-members. By agreeing to become a Member you opt-in
            to receiving an occasional special offer, marketing, survey and
            Product based communication emails. You can easily unsubscribe from
            Savor commercial emails by following the opt-out instructions in
            these emails. Savor memberships and subscriptions are not
            transferable and therefore cannot be sold or exchanged or
            transferred in any way whatsoever.
          </ParagraphTwo>

          <ParagraphTwo>
            Subscriptions. Savor account holders may access the Products in two
            ways (each a “Subscription”):
          </ParagraphTwo>

          <ParagraphTwo>
            "Basics" Free Trial: a free-of-charge program, which gives partial
            access to the Products for a period of seven days.
          </ParagraphTwo>

          <ParagraphTwo>
            Paid Subscription: a subscription fee-based program, which gives
            access to all content including and beyond the "Basics" Free Trial.
          </ParagraphTwo>

          <ParagraphTwo>
            You will only have access to your applicable Subscription while your
            Subscription is active and subsisting. You may have access to a free
            trial Subscription in accordance with certain promotional offers.
            All Subscription services provide access through the Products. You
            can become a subscriber to a Subscription by purchasing a
            subscription to the Products within the Apps, where allowed by the
            App marketplace partners, or through a bundle with one or more of
            our bundle subscription partners. Please note that if you purchase a
            subscription through the Apple iTunes Store or our iPhone
            application, the sale is final, and we will not provide a refund.
            Your purchase will be subject to Apple’s applicable payment policy,
            which also may not provide for refunds. If you purchase a
            subscription through the Google Play store, the sale is final and we
            will not provide a refund. Your purchase will be subject to Google’s
            applicable payment policy, which also may not provide for refunds.
            If you purchase through one or more of our bundle subscription
            partners, the purchase may be further subject to the Terms and
            Conditions of such partners, and payment and management of the
            bundle subscription may be administered by them.
          </ParagraphTwo>

          <ul>
            <li>
              Types of Paid Subscriptions. Savor offers monthly, annual and
              forever paid Subscription options. For the purposes of our monthly
              and yearly subscriptions, a month constitutes 30 calendar days and
              a year constitutes 365 calendar days. For the purposes of our
              forever subscription, forever constitutes 100 years or until the
              date Savor ceases to commercially offer the Products. Our
              “Monthly” subscription is paid in monthly installments. For each
              month that your monthly subscription is active, you acknowledge
              and agree that Savor is authorized to implement payment through
              the third party payment processor (i.e. Apple iTunes Store or
              Google Play store) (the “Payment Method”) in the amount of the
              current monthly subscription fee as of the time of renewal. The
              monthly renewal subscription fees will continue to be billed via
              the Payment Method automatically until canceled. You must cancel
              your subscription before it renews each month in order to avoid
              billing of the next month’s subscription fee. Refunds cannot be
              claimed for any partial-month subscription period. Our “Yearly”
              subscription is paid for by an upfront one-off payment with
              automatic annual renewals. You must cancel your subscription
              before it renews in order to avoid billing of the renewal
              subscription fee to via the Payment Method. Refunds cannot be
              claimed for any partial subscription period. Our “Forever”
              subscription is paid for by a one-off upfront payment.
            </li>
            <li>
              Discount Pricing. Savor currently offers and my offer in the
              future certain special discount pricing options (the “Special
              Discount Pricing Options”). The Special Discount Pricing Options
              will permit users to access to the same content included in the
              Paid Subscription. Special Discount Pricing Options shall only be
              available to qualified users (the “Qualified Users”) or to users
              you have a special discount code. Savor reserves the right to
              determine if you are a Qualifying User or whether you are entitled
              to use a discount code and to discontinue or change the terms of
              Special Discount Pricing Options from time to time in our sole
              discretion. Any Discount Pricing Options provided by us may not be
              used in conjunction with any other promotion code or offer, past
              or present. Introductory offers are only available to new users of
              the Products, except where expressly stated otherwise. Previous
              users or trial users of the Products do not qualify as new users.
              Unless otherwise set forth in the terms of any promotion, all
              Special Discount Pricing Options will apply to the initial period
              of the Subscription, and any renewals will be charged at the rate
              in effect at the time of renewal for the type of Subscription
              purchased.
            </li>
            <li>
              Price Changes. We reserve the right to change our Subscription
              plans or adjust pricing for our Products or any components thereof
              in any manner and at any time as we may determine in our sole and
              absolute discretion. Except as otherwise expressly provided for in
              these Terms, any price changes or changes to your subscription
              plan will take effect following notice to you.
            </li>
            <li>
              Fees and Charges. You are responsible for all applicable fees and
              charges incurred, including applicable taxes, and all
              Subscriptions purchased by you.
            </li>
            <li>
              Device Requirements. To enjoy Savor via your smartphone or other
              Device, your Device must satisfy certain system requirements.
              These requirements can be found on the Website and the Google,
              Apple and Amazon App marketplaces.
            </li>
            <li>
              Gift Subscriptions. “Gift Subscriptions” are pre-paid memberships
              to the Products. A person who purchases the gift is referred to in
              these terms as the “Giftor”. A person who receives and redeems a
              Gift Subscription to the Products is referred to in these terms as
              the “Recipient”. Gift subscriptions are paid for as a one-off
              upfront payment. Once bought, the Giftor will receive an Order
              confirmation and receipt. The Savor gift subscription will be sent
              to the Recipient on the Giftor’s specified date. Gifting codes can
              only be used once in the country for which they were purchased and
              cannot be redeemed for cash, resold or combined with any other
              offers, including a free trial. Please note that gifting codes
              cannot be redeemed if the Recipient has already purchased a
              subscription through the Apple iTunes Store or our iPhone
              application, or the Google Play Store or our Android application.
              We will automatically bill the Payment Method you provided for any
              purchased Gift Subscriptions at the time of purchase, not
              delivery. There are no refunds or other credits for Gift
              Subscription that are not redeemed. Savor will notify the
              Recipient prior to the end of the Gift Subscription that the gift
              period is about to expire. Savor is not responsible if a Gift
              Subscription is lost, stolen or used without permission.
            </li>
          </ul>

          <HeadingThree>3. CANCELLATION OF SERVICES</HeadingThree>

          <ul>
            <li>
              Cancellation by You. You may cancel a Monthly or Yearly
              subscription at any time. Cancellation is effective at the end of
              the applicable monthly or annual period. If you purchased a
              subscription through the Apple iTunes Store or our iPhone
              application, you may cancel your subscription by canceling
              automatic renewal of paid In App Subscriptions by selecting Manage
              App Subscriptions in your iTunes Account settings and selecting
              the subscription you want to modify. If you purchased a
              subscription through the Google Play store you may cancel
              automatic renewals in account settings under Subscriptions in the
              Google Play app, or according to the current process outlined by
              Google Play.
            </li>
            <li>
              Cancellation by Us. We may suspend or terminate your use of the
              Products as a result of your fraud or breach of any obligation
              under these Terms. Such termination or suspension may be immediate
              and without notice. A breach of these Terms, includes without
              limitation, the unauthorized copying or download of our audio or
              video content from the Products. No refund will be provided if we
              suspend or terminate your use of the Products.
            </li>
          </ul>

          <HeadingThree>4. AVAILABILITY OF PRODUCTS</HeadingThree>

          <ul>
            <li>
              No Guarantee. Although we aim to offer you the best service
              possible, we make no promise that the Products will meet your
              requirements and we cannot guarantee that the Products will be
              fault free. If a fault occurs in the Products, please report it to
              us at support@savorfood.co and we will review your complaint and,
              where we determine it is appropriate to do so, correct the fault.
              If the need arises, we may suspend access to the Products while we
              address the fault. We will not be liable to you if the Products
              are unavailable for a commercially reasonable period of time.
            </li>
            <li>
              Downtime. Your access to the Products may be occasionally
              restricted to allow for repairs, maintenance or the introduction
              of new facilities or Products. We will restore the Products as
              soon as we reasonably can. In the event that the Products are
              unavailable, our usual Order and cancellation deadlines apply;
              please notify us of changes to your Order by emailing
              support@savorfood.co.
            </li>
          </ul>

          <HeadingThree>5. PROHIBITED USE OF THE PRODUCTS</HeadingThree>

          <ul>
            <li>
              No Viruses or Malware. You agree not to upload, post, email or
              otherwise send or transmit or introduce any material that contains
              software viruses or any other computer code, files or programs
              designed to interrupt, harm, damage, destroy or limit the
              functionality of any computer software or hardware or equipment
              linked directly or indirectly with the Products or the Products
              themselves. You agree not to interfere with the servers or
              networks underlying or connected to the Products or to violate any
              of the procedures, policies or regulations of networks connected
              to the Products. You may not access the Products in an
              unauthorized manner.
            </li>
            <li>
              No Improper Conduct. You agree not to upload, post, email or
              otherwise send or transmit any content that (a) infringes,
              misappropriates or violates a third party’s patent, copyright,
              trademark, trade secret, moral rights or other intellectual
              property rights, or right of publicity or privacy; (b) violates or
              encourages any conduct that would violate any applicable law or
              regulation or that would give rise to liability; (c) is
              fraudulent, false, misleading or deceptive; (d) is defamatory,
              obscene, pornographic, vulgar or offensive; (e) promotes
              discrimination, bigotry, racism, hatred, harassment or harm
              against any individual or group; (f) is violent or threating or
              promotes violence or actions that are threatening to any person or
              entity; or (g) promotes illegal or harmful activities or
              substances.
            </li>
            <li>
              No Impersonation. You agree not to impersonate any other person
              while using the Products or use the Products for any illegal,
              immoral or harmful purpose.
            </li>
            <li>
              Criminal Offense. By breaching the provisions of section 4, you
              may commit a criminal offense under applicable laws. We may report
              any such breach to the relevant law enforcement authorities and we
              may cooperate with those authorities by disclosing your identity
              to them. In the event of such a breach, your right to use the
              Products will cease immediately.
            </li>
            <li>
              No Use for Scientific Research. You agree not to use the Products
              for any purposes related to scientific research, analysis or
              evaluation of the Products without the express written consent of
              Savor.
            </li>
          </ul>

          <HeadingThree>6. LICENSE AND INTELLECTUAL PROPERTY</HeadingThree>

          <ul>
            <li>
              License to Use Products. Subject to the terms of the license as
              set out in the Terms, and your payment of applicable subscription
              fees, Savor grants you a limited, non-exclusive, revocable license
              to stream, download and make personal non-commercial use of the
              Products. The Products contain or embody copyrighted material,
              proprietary material or other intellectual property of Savor or
              its licensors. All right, title and ownership in the Products
              remain with Savor or its licensors, as applicable. The rights to
              download and use the Products are licensed to you and are not
              being sold to you, and you have no rights in them other than to
              use them in accordance with this License Agreement and our other
              Terms
            </li>
            <li>
              Ownership and License of Materials. The Products and all materials
              (including software and content whether downloaded or not)
              contained in the Products and all intellectual property rights in
              and to the Products and to such materials are owned by Savor (or
              our affiliates and/or third-party licensors, where applicable),
              unless indicated otherwise. You agree and acknowledge that the
              materials are valuable property and that other than any specific
              and limited license for use of such materials, you shall not
              acquire any ownership rights in or to such materials. The
              materials may not be used except as provided for in these Terms,
              and any other relevant terms and conditions provided to you
              without our prior written permission. Audio or video content from
              Savor not explicitly indicated as downloadable may not be
              downloaded or copied from the Products or any Device.
            </li>
            <li>
              No Commercial Use. The Products are not intended for your
              commercial use. Commercial advertisements, affiliate links, and
              other forms of solicitation may be removed by us without notice
              and may result in termination of privileges. You must not use any
              part of the materials used in or on the Products for commercial
              purposes without obtaining a written license to do so from us.
              Material from the Products may not be copied or distributed, or
              republished, or transmitted in any way, without our prior written
              consent. Any unauthorized use or violation of these Terms
              immediately and automatically terminates your right to use the
              Products and may subject you to legal liability. You agree not to
              use the Products for illegal purposes (including, without
              limitation, unlawful, harassing, libelous, invasion of another’s
              privacy, abusive, threatening or obscene purposes) and you agree
              that you will comply with all laws, rules, and regulations related
              to your use of the Products. Appropriate legal action may be taken
              for any illegal or unauthorized use of the Products.
            </li>
            <li>
              Limited License to Distribute. A limited amount of content may be
              marked and authorized for the user to share in their personal
              social channels (Facebook, Twitter, etc.). With respect to content
              made available by Savor through the Products that is specifically
              identified as available for distribution by you (“Distribution
              Content”) as part of your blog or other online commentary,
              analysis or review (“User Commentary”), Savor grants you a limited
              right to download, reproduce and distribute Distribution Content
              over the internet as part of your User Commentary. You may also
              modify such Distribution Content but only as required to
              technically enable the display and distribution of such content
              through your computer systems and over the Internet (e.g. a change
              in video format or file size) provided such modification does not
              materially alter the substance or quality of such content. Your
              display and distribution of Distribution Content may also be
              subject to other terms and conditions that are set forth in the
              description of such content in the Products, such as display and
              distribution of Distribution Content only within specified usage
              dates. You agree not to publish the Distribution Content with
              other content that is known by you to be false, inaccurate, or
              misleading or that is, or that encourages activity or conduct that
              is, unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, pornographic, libelous, invasive of
              another’s privacy, hateful, or racially, ethnically or otherwise
              objectionable. Distribution Content may contain trackers that
              enable us to collect information with respect to the distribution
              and consumption of such content.
            </li>
            <li>
              Prohibitions. You agree that you will not and you will not assist
              or permit any third party to:
            </li>
            <li>
              copy, store, reproduce, transmit, modify, alter, reverse-engineer,
              emulate, de-compile, or disassemble the Products in any way, or
              create derivative works of the Products;
            </li>
            <li>
              use the Products or any part of them to create any tool or
              software product that can be used to create software applications
              of any nature whatsoever;
            </li>
            <li>
              rent, lease, loan, make available to the public, sell or
              distribute the Products in whole or in part;
            </li>
            <li>
              tamper with the Products or circumvent any technology used by
              Savor or its licensors to protect any content accessible through
              the Products;
            </li>
            <li>
              circumvent any territorial restrictions applied to the Products;
              or
            </li>
            <li>
              use the Products in a way that violates this License Agreement or
              the other Terms.
            </li>
          </ul>

          <ParagraphTwo>
            You may not make the Products available to the public. The Products
            made available (in whole or in part) are owned by Savor or its
            licensors and your use of them must be in accordance with these
            Terms.
          </ParagraphTwo>

          <ul>
            <li>
              No Downloading or Duplication. You may not otherwise download,
              display, copy, reproduce, distribute, modify, perform, transfer,
              create derivative works from, sell or otherwise exploit any
              content, code, data or materials in the Products. If you make
              other use of the Products, or the content, code, data or materials
              thereon, except as otherwise provided above, you may violate
              copyright and other laws of Canada and/or other countries and may
              be subject to liability for such unauthorized use. Savor will
              enforce its intellectual property rights to the fullest extent of
              the law, including the seeking of criminal prosecution.
            </li>
            <li>
              Trademarks. All intellectual property, trademarks, logos, images,
              product and company names displayed or referred to on or in the
              Products are the property of Savor or their respective owners.
              Nothing grants you any license or right to use, alter or remove or
              copy such material. Your misuse of the trademarks displayed on the
              Products is strictly prohibited. Savor will enforce its trademark
              rights to the fullest extent of the law, including the seeking of
              criminal prosecution.
            </li>
          </ul>

          <HeadingThree>7. USER MATERIAL</HeadingThree>

          <ul>
            <li>
              User Material. The Products may let you submit material to us: for
              example, you may be able to upload a photo to your profile, post
              subjects, and comments in the community and comment on various
              matters in various parts of the Products. You may be able to
              upload video, images or sounds. In these Terms, we use the term
              “User Material” to refer to any publicly available material of any
              kind that you submit to us, including text, files, images, photos,
              video, sounds, and musical or literary works. User Material does
              not include the account information, Product purchase, or Product
              use information which you provide in registering for and using
              Products. This Part sets out the rights and obligations that each
              of us has in connection with User Material. If you review or
              submit User Material, you are agreeing to do so in accordance with
              these Terms. If you do not want to review or submit User Material
              in accordance with these Terms, then you should not do so.
            </li>
            <li>
              No Review. We do not systematically review User Material submitted
              by you or other users. We are not responsible for the content of
              User Material provided by you or any other user. We do not
              necessarily endorse any opinion contained in such material. We
              make no warranties or representations, express or implied, about
              User Material, including as to its legality or accuracy.
            </li>
            <li>
              Discretion to Remove. We reserve the right, in our sole
              discretion, to refuse to post or to remove or edit any of your
              User Material, or to restrict, suspend, or terminate your access
              to all or any part of the Products, particularly where User
              Material breaches this Part, and we may do this with or without
              giving you any prior notice.
            </li>
            <li>
              Linkage and Use. We may link User Material or parts of User
              Material to other material, including material submitted by other
              users or created by Savor or other third parties. We may use User
              Material for our internal business purposes, for example, to
              examine trends or categories or to promote, market or advertise
              Savor. You acknowledge that we may indirectly commercially benefit
              from the use of your User Material.
            </li>
            <li>
              Representations. Each time you submit User Material to us, you
              represent and warrant to us as follows:
            </li>
            <li>
              you own or otherwise control the rights to your User Material or
              have the right to submit it, and in submitting it you will not be
              infringing any rights of any third party, including intellectual
              property rights (such as copyright or trademark), privacy or
              publicity rights, rights of confidentiality or rights under
              contract;
            </li>
            <li>
              your User Material is not illegal, obscene, defamatory,
              threatening, pornographic, harassing, hateful, racially or
              ethnically offensive, and does not encourage conduct that would be
              considered a criminal offense, and does not give rise to civil
              liability, violate any law, or is otherwise deemed inappropriate;
            </li>
            <li>
              your User Material does not advertise any product or service or
              solicit any business;
            </li>
            <li>
              your User Material does not identify any individual (including by
              way or name, address or a still picture or video) under the age of
              18 and if User Material identifies any individual over the age of
              18, you have that person’s consent to being identified in exactly
              that way in your User Material; and in submitting your User
              Material you are not impersonating any other person;
            </li>
            <li>
              you will not collect email addresses of users for the purpose of
              sending unsolicited email;
            </li>
            <li>
              you will not engage in criminal or tortious activity, including
              fraud, spamming, spimming, sending of viruses or other harmful
              files, copyright infringement, patent infringement, or theft of
              trade secrets or attempt to impersonate another user or person;
            </li>
            <li>
              you will not engage in any automated use of the system, such as
              using scripts to alter our content;
            </li>
            <li>
              you will not, without authorization, access, tamper with, or use
              non-public
            </li>
            <li>
              except as necessary to maintain your own computer security by use
              of commercial-off-the-shelf anti-virus or anti-malware products,
              you will not attempt to probe, scan, or test the vulnerability of
              the Products or any other Savor system or network or breach any
              security or authentication measures.
            </li>
            <li>
              Copyright Protection. We are committed to complying with copyright
              and related laws, and we require all users of the Products to
              comply with these laws. Accordingly, you may not store any
              material or content on, or disseminate any material or content
              over, the Products in any manner that constitutes an infringement
              of third party intellectual property rights, including rights
              granted by copyright law. You may not post, modify, distribute, or
              reproduce in any way any copyrighted material, trademarks, or
              other proprietary information belonging to others without
              obtaining the prior written consent of the owner of such
              proprietary rights. It is our policy to terminate privileges of
              any user who repeatedly infringes the copyright rights of others
              upon receipt of proper notification to us by the copyright owner
              or the copyright owner’s legal agent. If you feel that a posted
              message is objectionable or infringing, we encourage you to
              contact us immediately. Upon our receipt of a proper notice of
              claimed infringement, we will respond expeditiously to remove or
              disable access to, the material claimed to be infringing and will
              take appropriate steps to resolve the claim between the notifying
              party and the alleged infringer who provided the content in issue.
              If you believe that your work has been copied and posted on the
              Products in a way that constitutes copyright infringement, please
              contact us with sufficient details to support your claim and
              details how to contact you at support@savorfood.co.
            </li>
            <li>
              Indemnification. You agree to indemnify Savor and its affiliates
              for all claims arising from or in connection with any claims to
              any rights in your User Material, any damages arising from your
              User Material or any breaches of any representations or warranties
              related to your User Materials.
            </li>
            <li>
              Disclosure of Your Identity. We are entitled to identify you to
              third parties who claim that their rights have been infringed by
              User Material you have submitted.
            </li>
            <li>
              No Confidentiality. User Material is not considered to be
              confidential. You agree not to submit any content as User Material
              in which you have any expectation of privacy.
            </li>
            <li>
              License. We do not claim any ownership rights in User Material.
              However, by submitting User Material you hereby grant Savor an
              irrevocable, perpetual, non-exclusive, royalty-free, worldwide
              license to use, telecast, copy, perform, display, edit, distribute
              and otherwise exploit the User Material you post on the Products
              or any portion thereof, and any ideas, concepts, or know how
              contained therein, with or without attribution, and without the
              requirement of any permission from or payment to you or to any
              other person or entity, in any manner (including, without
              limitation, for commercial, publicity, trade, promotional, or
              advertising purposes) and in any and all media now known or
              hereafter devised, and to prepare derivative works of, or
              incorporate into other works, such User Material, and to grant and
              authorize sublicenses of the foregoing without any payment of
              money or any other form of consideration to you or to any third
              party. Savor may include your User Material in Savor’s
              Distribution Content that is made available to others through the
              Products. Be aware that Savor has no control over User Material
              once it leaves the Products, and it is possible that others may
              duplicate material found on the Products, including, but not
              limited to, on other sites on the Internet.
            </li>
            <li>
              Submissions. Any inquiries, feedback, suggestions, ideas, other
              information which is not part of your use of the Products or User
              Material that you provide to us (collectively, “Submissions”) will
              be treated as non-proprietary and non-confidential. By
              transmitting, uploading, posting, e-mailing, or otherwise
              submitting Submissions to the Products, you grant, and you
              represent and warrant that you have the right to grant, to Savor
              an irrevocable, perpetual, non-exclusive, royalty-free, worldwide
              license to use, telecast, copy, perform, display, edit, distribute
              and otherwise exploit the Submissions, or any portion thereof and
              any ideas, concepts, or know how contained therein, with or
              without attribution, and without the requirement of any permission
              from or payment to you or to any other person or entity, in any
              manner (including, without limitation, for commercial, publicity,
              trade, promotional, or advertising purposes) and in any and all
              media now known or hereafter devised, and to prepare derivative
              works of, or incorporate into other works, such Submissions, and
              to grant and authorize sublicenses of the foregoing without any
              payment of money or any other form of consideration to you or to
              any third party. You also acknowledge that your Submissions will
              not be returned to you and that Savor has no obligation to
              acknowledge receipt of or respond to any Submissions. If you make
              a Submission, you represent and warrant that you own or otherwise
              control the rights to your Submission. You agree to indemnify
              Savor and its affiliates for all claims arising from or in
              connection with any claims to any rights in any Submission or any
              damages arising from any Submission.
            </li>
          </ul>

          <HeadingThree>8. LINKS TO WEBSITES/HOME PAGE</HeadingThree>

          <ul>
            <li>
              Links to Other Websites. We may provide links to other websites or
              services for you to access. You acknowledge that any access is at
              your sole discretion and for your information only. We do not
              review or endorse any of those websites or services. We are not
              responsible in any way for:(a) the availability of, (b) the
              privacy practices of, (c) the content, advertising, products,
              goods or other materials or resources on or available from, or (d)
              the use to which others make of these other websites or services.
              We are also not responsible for any damage, loss or offense caused
              or alleged to be caused by, or in connection with, the use of or
              reliance on such websites or services.
            </li>
            <li>
              Link to Our Website. You may link to our home page, provided you
              do so in a way that is fair and legal and does not damage our
              reputation or take advantage of it, but you must not establish a
              link in such a way as to suggest any form of association, approval
              or endorsement on our part where none exists. You must not
              establish a link from any website that is not owned by you. The
              Products must not be framed on any other website, nor may you
              create a link to any part of the Products unless you have written
              permission to do so from Savor. We reserve the right to withdraw
              linking permission with written notice. The website from which you
              are linking must comply in all respects with the content standards
              set out in our acceptable use policy. If you wish to make any use
              of material on or in the Products other than that set out above,
              please address your request to{" "}
              <a href="mailto:support@savor.co">support@savor.co</a>.
            </li>
          </ul>

          <HeadingThree>9. DISCLAIMER</HeadingThree>

          <ul>
            <li>
              Products Disclaimer. The information contained in the Products is
              for general information purposes only. While we endeavor to keep
              the information up-to-date and correct, we make no representations
              or warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the Products or the information contained on the
              Products for any purpose. Any reliance you place on such
              information is therefore strictly at your own risk.
            </li>
            <li>
              Medical Disclaimer. Savor is a provider of online content in the
              food, health & wellness space. We are not a health care or medical
              device provider, nor should our Products be considered medical
              advice. Only your physician or other health care provider can do
              that. Savor makes no claims, representations or guarantees that
              the Products provide a therapeutic benefit. Any health information
              and links on the Products, whether provided by Savor or by
              contract from outside providers, is provided simply for your
              convenience. Any advice or other materials in the Products are
              intended for general information purposes only. They are not
              intended to be relied upon and are not a substitute for
              professional medical advice based on your individual condition and
              circumstances. The advice and other materials we make available
              are intended to support the relationship between you and your
              healthcare providers and not replace it. We are not liable or
              responsible for any consequences of your having read or been told
              about such advice or other materials as you assume full
              responsibility for your decisions and actions. In particular, to
              the fullest extent permitted by law, we make no representation or
              warranties about the accuracy, completeness, or suitability for
              any purpose of the advice, other materials, and information
              published as part of the Products.
            </li>
          </ul>

          <HeadingThree>10. GENERAL TERMS AND CONDITIONS</HeadingThree>

          <ul>
            <li>
              Assignment. Savor may transfer its rights and obligations under
              these Terms to any company, firm or person at any time if it does
              not materially affect your rights under it. You may not transfer
              your rights or obligations under these Terms to anyone else. These
              Terms are personal to you and no third party is entitled to
              benefit under these Terms except as set out here.
            </li>
            <li>
              Indemnity. You agree to defend, indemnify and hold Savor and its
              directors, officers, members, investors, managers, employees and
              agents harmless from any and all claims, liabilities, costs and
              expenses, including reasonable attorneys’ fees, arising in any way
              from your use of the Products, your placement or transmission of
              any message, content, information, software, or other submissions
              through the Products, or your breach or violation of the law or of
              these Terms. Savor reserves the right, at its own expense, to
              assume the exclusive defense and control of any matter otherwise
              subject to indemnification by you, and in such case, you agree to
              cooperate with Savor defense of such claim.
            </li>
            <li>
              Products Provided As Is. The Products and their content are
              otherwise provided on an “as is” basis and we make no
              representations or warranties of any kind with respect to them,
              including as to the accuracy, completeness or currency of the
              Products or their content. We assume no liability or
              responsibility for any errors or omissions in the content of the
              Products or any failures, delays, or interruptions in the
              provision of the Products. We disclaim and exclude any express or
              implied warranties or representations, including any warranties as
              to merchantability or fitness for a particular purpose of the
              Products to the broadest extent permitted by law. We make no
              warranties or representations, express or implied, as to the
              timeliness, accuracy, quality, completeness or existence of the
              content and information posted on the Products. We make no
              warranties or representations, express or implied, for technical
              accessibility, fitness or flawlessness of the Products. We make no
              warranties or representations that your use of content and
              information posted on the Products will not infringe rights of
              third parties. All conditions, warranties and other terms which
              might otherwise be implied by statute, common law or the law of
              equity are, to the extent permitted by law, excluded.
            </li>
            <li>
              Limitations on Liability. NOTWITHSTANDING ANYTHING HEREIN
              CONTAINED, UNDER NO CIRCUMSTANCES WILL SAVOR AND ITS AFFILIATES,
              SUPPLIERS, CLIENTS OR LICENSORS BE LIABLE OR RESPONSIBLE FOR ANY
              AMOUNT OF DAMAGES RELATING TO THE PURCHASE OR USE OF THE PRODUCTS
              FOR AN AGGREGATE AMOUNT IN EXCESS OF THE AGGREGATE DOLLAR AMOUNT
              PAID BY YOU FOR THE PRODUCTS. FURTHERMORE, IN NO EVENT SHALL SAVOR
              AND ITS AFFILIATES, SUPPLIERS, CLIENTS OR LICENSORS BE LIABLE FOR
              ANY INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
              INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS OR ANTICIPATED
              PROFITS, BUSINESS INTERRUPTION, LOSSES OR OTHER ECONOMIC LOSS IN
              CONNECTION WITH OR OTHERWISE ARISING OUT OF YOUR USE OF PRODUCTS
              PROVIDED.
            </li>
            <li>
              No Waiver. If we delay exercising or fail to exercise or enforce
              any right available to us under these Terms, such delay or failure
              does not constitute a waiver of that right or any other rights
              under these Terms. ​
            </li>
            <li>
              Force Majeure. We will not be liable to you for any lack of
              performance, or the unavailability or failure, of the Products, or
              for any failure or delay by us to comply with these Terms, where
              such lack, unavailability or failure arises from any cause beyond
              our reasonable control.
            </li>
            <li>
              Interpretation. In these Terms, unless the context requires
              otherwise: i) any phrase introduced by the words “including”,
              “include”, “in particular”, “for example” or any similar
              expression shall be construed as illustrative only and shall not
              be construed as limiting the generality of any preceding words;
              and ii) references to the singular include the plural and to the
              masculine include the feminine, and in each case vice versa.
            </li>
            <li>
              Notices. Unless otherwise specifically indicated, all notices
              given by you to us must be given to Savor at support@savorfood.co.
              We may give notice to you at the e-mail address you provide to us
              when you register. Notice will be deemed received and properly
              served immediately when posted on the Products or when an e-mail
              or other electronic communication is sent. In proving the service
              of any notice via email, it will be sufficient to prove that such
              e-mail was sent to the specified e-mail address of the addressee.
            </li>
            <li>
              Entire Agreement. These Terms and any document expressly referred
              to in them constitute the whole agreement between us and supersede
              all previous discussions, correspondence, negotiations, previous
              arrangement, understanding or agreement between us relating to
              their subject matter. We each acknowledge that neither of us
              relies on, or will have any remedies in respect of, any
              representation or warranty (whether made innocently or
              negligently) that is not set out in these Terms or the documents
              referred to in them. Each of us agrees that our only liability in
              respect of those representations and warranties that are set out
              in this agreement (whether made innocently or negligently) will be
              for breach of contract.
            </li>
            <li>
              Governing Laws. These Terms shall be governed and construed by the
              laws of the Province of British Columbia and the federal laws of
              Canada applicable therein.
            </li>
            <li>
              Arbitration. Subject to section 10.12, you and Savor waive any
              constitutional and statutory rights to go to court and have a
              trial in front of a judge or a jury. Rather, you and Savor elect
              to have claims and disputes resolved by arbitration. If we are
              unable to resolve a dispute, then either of us may refer the
              matters in dispute for determination by arbitration in Vancouver,
              British Columbia by a single arbitrator in accordance with the
              provisions of the Arbitration Act (British Columbia). The costs of
              arbitration shall be awarded by the arbitrator. A decision of the
              arbitration shall be final and binding on the parties.
            </li>
            <li>
              Class Action Waiver. Where permitted under the applicable law, you
              and Savor agree that each may bring claims against the other only
              in your or our individual capacity and not as a plaintiff or class
              member in any purported class or consolidated action. If, however,
              this waiver of class or consolidated actions is deemed invalid or
              unenforceable, neither you nor Savor are entitled to arbitration;
              instead all claims and disputes will be resolved in a court as set
              forth in section 10.13 below.
            </li>
            <li>
              Exclusive Venue. To the extent the parties are permitted under
              these Terms to initiate litigation in a court, both you and Savor
              hereby irrevocably attorn to the non-exclusive jurisdiction of the
              courts of the Province of British Columbia.
            </li>
            <li>
              Severability. If any provision of these Terms is found
              unenforceable, then that provision will be severed from these
              Terms and not affect the validity and enforceability of any
              remaining provisions.
            </li>
          </ul>
        </Col>
      </Wrapper>
    </Container>
  )
}
