import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import Terms from 'components/Terms/Terms';

const TermsPage = () => (
  <Layout>
    <SEO title="Terms &amp; Conditions" />
    <Terms />
  </Layout>
)

export default TermsPage